var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.keys(_vm.pricing).length
    ? _c(
        "section",
        { attrs: { id: "pricing-plan" } },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("h1", { staticClass: "mt-5" }, [_vm._v(" Pricing Plans ")]),
            _c("p", { staticClass: "mb-2 pb-75" }, [
              _vm._v(
                " All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your needs. "
              )
            ]),
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center justify-content-center mb-5 pb-50"
              },
              [
                _c("h6", { staticClass: "mr-1 mb-0" }, [_vm._v(" Monthly ")]),
                _c("b-form-checkbox", {
                  attrs: {
                    id: "priceSwitch",
                    name: "price-switch",
                    value: "annually",
                    "unchecked-value": "monthly",
                    switch: ""
                  },
                  on: { input: _vm.tooglePlan },
                  model: {
                    value: _vm.status,
                    callback: function($$v) {
                      _vm.status = $$v
                    },
                    expression: "status"
                  }
                }),
                _c("h6", { staticClass: "ml-50 mb-0" }, [_vm._v(" Annually ")])
              ],
              1
            )
          ]),
          _c(
            "b-row",
            { staticClass: "pricing-card" },
            [
              _c(
                "b-col",
                {
                  staticClass: "mx-auto",
                  attrs: {
                    "offset-sm-2": "",
                    sm: "10",
                    md: "12",
                    "offset-lg": "2",
                    lg: "10"
                  }
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "b-card",
                            { attrs: { align: "center" } },
                            [
                              _vm.pricing.basicPlan.img
                                ? _c("b-img", {
                                    staticClass: "mb-2 mt-5",
                                    attrs: {
                                      src: _vm.pricing.basicPlan.img,
                                      alt: "basic svg img"
                                    }
                                  })
                                : _vm._e(),
                              _c("h3", [
                                _vm._v(_vm._s(_vm.pricing.basicPlan.title))
                              ]),
                              _c("b-card-text", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.pricing.basicPlan.subtitle) +
                                    " "
                                )
                              ]),
                              _c("div", { staticClass: "annual-plan" }, [
                                _c("div", { staticClass: "plan-price mt-2" }, [
                                  _c(
                                    "sup",
                                    {
                                      staticClass:
                                        "font-medium-1 font-weight-bold text-primary"
                                    },
                                    [_vm._v("$")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "pricing-basic-value font-weight-bolder text-primary"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.monthlyPlanShow
                                            ? _vm.pricing.basicPlan.monthlyPrice
                                            : _vm.pricing.basicPlan.yearlyPlan
                                                .perMonth
                                        )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "sub",
                                    {
                                      staticClass:
                                        "pricing-duration text-body font-medium-1 font-weight-bold"
                                    },
                                    [_vm._v("/month")]
                                  )
                                ]),
                                _c(
                                  "small",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.monthlyPlanShow,
                                        expression: "!monthlyPlanShow"
                                      }
                                    ],
                                    staticClass: "annual-pricing text-muted"
                                  },
                                  [
                                    _vm._v(
                                      "USD " +
                                        _vm._s(
                                          _vm.pricing.basicPlan.yearlyPlan
                                            .totalAnual
                                        ) +
                                        " / year"
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "b-list-group",
                                { staticClass: "list-group-circle text-left" },
                                _vm._l(
                                  _vm.pricing.basicPlan.planBenefits,
                                  function(data, index) {
                                    return _c(
                                      "b-list-group-item",
                                      { key: index },
                                      [_vm._v(" " + _vm._s(data) + " ")]
                                    )
                                  }
                                ),
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(40, 199, 111, 0.15)",
                                      expression: "'rgba(40, 199, 111, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "mt-2",
                                  attrs: {
                                    block: "",
                                    variant: "outline-success"
                                  }
                                },
                                [_vm._v(" Your current plan ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "b-card",
                            {
                              staticClass: "popular",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.pricing.standardPlan.popular,
                                      expression: "pricing.standardPlan.popular"
                                    }
                                  ],
                                  staticClass: "pricing-badge text-right"
                                },
                                [
                                  _c(
                                    "b-badge",
                                    {
                                      attrs: {
                                        variant: "light-primary",
                                        pill: ""
                                      }
                                    },
                                    [_vm._v(" Popular ")]
                                  )
                                ],
                                1
                              ),
                              _vm.pricing.standardPlan.img
                                ? _c("b-img", {
                                    staticClass: "mb-1",
                                    attrs: {
                                      src: _vm.pricing.standardPlan.img,
                                      alt: "svg img"
                                    }
                                  })
                                : _vm._e(),
                              _c("h3", [
                                _vm._v(_vm._s(_vm.pricing.standardPlan.title))
                              ]),
                              _c("b-card-text", [
                                _vm._v(
                                  _vm._s(_vm.pricing.standardPlan.subtitle)
                                )
                              ]),
                              _c("div", { staticClass: "annual-plan" }, [
                                _c("div", { staticClass: "plan-price mt-2" }, [
                                  _c(
                                    "sup",
                                    {
                                      staticClass:
                                        "font-medium-1 font-weight-bold text-primary"
                                    },
                                    [_vm._v("$")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "pricing-basic-value font-weight-bolder text-primary"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.monthlyPlanShow
                                            ? _vm.pricing.standardPlan
                                                .monthlyPrice
                                            : _vm.pricing.standardPlan
                                                .yearlyPlan.perMonth
                                        )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "sub",
                                    {
                                      staticClass:
                                        "pricing-duration text-body font-medium-1 font-weight-bold"
                                    },
                                    [_vm._v("/month")]
                                  )
                                ]),
                                _c(
                                  "small",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.monthlyPlanShow,
                                        expression: "!monthlyPlanShow"
                                      }
                                    ],
                                    staticClass: "annual-pricing text-muted"
                                  },
                                  [
                                    _vm._v(
                                      "USD " +
                                        _vm._s(
                                          _vm.pricing.standardPlan.yearlyPlan
                                            .totalAnual
                                        ) +
                                        " / year"
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "b-list-group",
                                { staticClass: "list-group-circle text-left" },
                                _vm._l(
                                  _vm.pricing.standardPlan.planBenefits,
                                  function(data, index) {
                                    return _c(
                                      "b-list-group-item",
                                      { key: index },
                                      [_vm._v(" " + _vm._s(data) + " ")]
                                    )
                                  }
                                ),
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(255, 255, 255, 0.15)",
                                      expression: "'rgba(255, 255, 255, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "mt-2",
                                  attrs: { block: "", variant: "primary" }
                                },
                                [_vm._v(" Upgrade ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "b-card",
                            { attrs: { align: "center" } },
                            [
                              _vm.pricing.enterprisePlan.img
                                ? _c("b-img", {
                                    staticClass: "mb-2",
                                    attrs: {
                                      src: _vm.pricing.enterprisePlan.img,
                                      alt: "enterprise svg img"
                                    }
                                  })
                                : _vm._e(),
                              _c("h3", [
                                _vm._v(_vm._s(_vm.pricing.enterprisePlan.title))
                              ]),
                              _c("b-card-text", [
                                _vm._v(
                                  _vm._s(_vm.pricing.enterprisePlan.subtitle)
                                )
                              ]),
                              _c("div", { staticClass: "annual-plan" }, [
                                _c("div", { staticClass: "plan-price mt-2" }, [
                                  _c(
                                    "sup",
                                    {
                                      staticClass:
                                        "font-medium-1 font-weight-bold text-primary"
                                    },
                                    [_vm._v("$")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "pricing-basic-value font-weight-bolder text-primary"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.monthlyPlanShow
                                            ? _vm.pricing.enterprisePlan
                                                .monthlyPrice
                                            : _vm.pricing.enterprisePlan
                                                .yearlyPlan.perMonth
                                        )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "sub",
                                    {
                                      staticClass:
                                        "pricing-duration text-body font-medium-1 font-weight-bold"
                                    },
                                    [_vm._v("/month")]
                                  )
                                ]),
                                _c(
                                  "small",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.monthlyPlanShow,
                                        expression: "!monthlyPlanShow"
                                      }
                                    ],
                                    staticClass: "annual-pricing text-muted"
                                  },
                                  [
                                    _vm._v(
                                      "USD " +
                                        _vm._s(
                                          _vm.pricing.enterprisePlan.yearlyPlan
                                            .totalAnual
                                        ) +
                                        " / year"
                                    )
                                  ]
                                )
                              ]),
                              _vm._l(
                                _vm.pricing.enterprisePlan.planBenefits,
                                function(data, index) {
                                  return _c(
                                    "b-list-group",
                                    {
                                      key: index,
                                      staticClass: "list-group-circle text-left"
                                    },
                                    [
                                      _c("b-list-group-item", [
                                        _vm._v(" " + _vm._s(data) + " ")
                                      ])
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(113, 102, 240, 0.15)",
                                      expression: "'rgba(113, 102, 240, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "mt-2",
                                  attrs: {
                                    block: "",
                                    variant: "outline-primary"
                                  }
                                },
                                [_vm._v(" Upgrade ")]
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pricing-free-trial" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "mx-auto",
                      attrs: { lg: "10", "offset-lg": "3" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pricing-trial-content d-flex justify-content-between"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-center text-md-left mt-3" },
                            [
                              _c("h3", { staticClass: "text-primary" }, [
                                _vm._v(
                                  " Still not convinced? Start with a 14-day FREE trial! "
                                )
                              ]),
                              _c("h5", [
                                _vm._v(
                                  "You will get full access to with all the features for 14 days."
                                )
                              ]),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(255, 255, 255, 0.15)",
                                      expression: "'rgba(255, 255, 255, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "mt-2 mt-lg-3",
                                  attrs: { variant: "primary" }
                                },
                                [_vm._v(" Start 14-day FREE trial ")]
                              )
                            ],
                            1
                          ),
                          _c("b-img", {
                            staticClass: "pricing-trial-img",
                            attrs: {
                              fluid: "",
                              src: require("@/assets/images/illustration/pricing-Illustration.svg"),
                              alt: "svg img"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pricing-faq" },
            [
              _c("h3", { staticClass: "text-center" }, [_vm._v(" FAQ's ")]),
              _c("p", { staticClass: "text-center" }, [
                _vm._v(" Let us help answer the most common questions. ")
              ]),
              _c(
                "b-row",
                { staticClass: "py-2" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "mx-auto",
                      attrs: { lg: "10", "offset-lg": "2" }
                    },
                    [
                      _c(
                        "app-collapse",
                        { attrs: { accordion: "", type: "margin" } },
                        _vm._l(_vm.pricing.qandA, function(data, index) {
                          return _c(
                            "app-collapse-item",
                            { key: index, attrs: { title: data.question } },
                            [_vm._v(" " + _vm._s(data.ans) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }